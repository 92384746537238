






































































































































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import Calendar from '@/common/calendar.vue'
import { Action } from 'vuex-class'
import {
  ACTIVATE_EVENT, BOOKMARK_EVENT,
  DEACTIVATE_EVENT,
  DELETE_EVENT,
  GET_ALL_EVENTS,
  GET_ALL_EVENTS_BY_COMPETENCES, GET_ALL_TOPICS
} from '@/store/event/actions.type'
import { SimpleServerResponse } from '@/store/types'
import { Competences, Event } from '@/store/event/types'
import EventSearchResult from '@/components/search-results/EventSearchResult.vue'
import {
  getAllBaseCompetencesAsKeyValue,
  getAllDatesBetweenTwoDates,
  getBaseCompetenceObjectArray, getBaseCompetenceTitleArray,
  getCompetenceObjectArray,
  getCompetenceTitleArray, getTopicObjectArray,
  isDateBetween
} from '@/common/globals'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { GET_ALL_COMPETENCES, GET_COMPETENCES_TO_IMPROVE_OWN } from '@/store/kode/actions'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import BaseForm from '@/components/forms/BaseForm.vue'
import { isDateBefore } from '@/common/datePicker'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import { CompetenceImprovement } from '@/store/kode/types'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
  @Component({
    components: { ResultsPage, BaseForm, ConfirmationModal, EventSearchResult, Calendar }
  })
/*
Used in the EventsOverview and the CalenderAdministration, this component is the heart of the events.
It displays the eventResults, the calender to select dates and filters.
@group EVENTS
 */
export default class CalendarContainer extends Vue {
    // if its writable (mostly used in combination with role rights)
    @Prop()
    writeable: boolean

    // if the user is able to manage events - like publish, edit, delete (mostly used in combination with role rights)
    @Prop()
    manage: boolean

    // if the user is able to bookmark events
    @Prop()
    bookmarkable: boolean

    @Action(GET_ALL_COMPETENCES)
    public getAllCompetences: () => Promise<Array<string>>

    @Action(GET_ALL_TOPICS)
    public getAllTopics: () => Promise<Array<string>>

    @Action(GET_ALL_EVENTS_BY_COMPETENCES)
    public getEventsByCompetences: (competences: Competences) => Promise<Array<Event>>

    @Action(GET_ALL_EVENTS)
    public getEvents: () => Promise<Array<Event>>

    @Action(ACTIVATE_EVENT)
    public activateEvent: (id: string | undefined) => Promise<SimpleServerResponse>

    @Action(DEACTIVATE_EVENT)
    public deactivateEvent: (id: string | undefined) => Promise<SimpleServerResponse>

    @Action(DELETE_EVENT)
    public deleteEvent: (id: string | undefined) => Promise<SimpleServerResponse>

    @Action(BOOKMARK_EVENT)
    public bookmarkEvent: (event: Event) => Promise<SimpleServerResponse>

    @Action(GET_COMPETENCES_TO_IMPROVE_OWN)
    public getCompetencesToImproveOwn: () => Promise<SimpleServerResponse>

    selectedEvent: Event = {
      title: '',
      description: '',
      start: '',
      end: ''
    }

    filtered: {
      competences: KeyValue[];
      baseCompetences: KeyValue[];
      topics: KeyValue[];
    } = {
      competences: [],
      baseCompetences: [],
      topics: []
    }

    events: Event[] = []
    filteredEvents: Event[] = []
    editEvent = {}
    dates: any = []
    inactiveDates: any = []
    bookmarkedDates: any = []
    selectedDate: Date | string | undefined = undefined

    competenceOptions: KeyValue[] = []

    topicOptions: KeyValue[] = []

    improvementData: CompetenceImprovement = {}
    checkboxFormModel = { filterOwnSelectedCompetences: false }

    get improvementCompetences (): string[] {
      if (this.improvementData) {
        if (this.improvementData.baseCompetenceToImprove) {
          return getBaseCompetenceTitleArray([this.improvementData.baseCompetenceToImprove])
        } else if (this.improvementData.competencesToImprove) {
          return getCompetenceTitleArray(this.improvementData.competencesToImprove)
        } else return []
      } else return []
    }

    get baseCompetenceOptions (): KeyValue[] {
      return getAllBaseCompetencesAsKeyValue()
    }

    get baseCompetenceFields (): Form[] {
      return [
        {
          id: 'baseCompetences',
          key: 'baseCompetences',
          type: InputTypes.MULTISELECT,
          label: this.$i18n.t('events.base-competence').toString(),
          options: this.baseCompetenceOptions,
          sm: 12,
          md: 12
        }
      ]
    }

    get competenceFields (): Form[] {
      return [
        {
          id: 'competences',
          key: 'competences',
          type: InputTypes.MULTISELECT,
          label: this.$i18n.t('events.competence').toString(),
          options: this.competenceOptions,
          sm: 12,
          md: 12
        }
      ]
    }

    get topicFields (): Form[] {
      return [
        {
          id: 'topics',
          key: 'topics',
          type: InputTypes.MULTISELECT,
          label: this.$i18n.t('events.topic').toString(),
          options: this.topicOptions,
          sm: 12,
          md: 12
        }
      ]
    }

    getFilteredEvents (): Event[] {
      if (this.selectedDate) {
        return this.events.filter(
          event => isDateBetween(this.selectedDate, event.start, event.end)
        ).map((ev: Event) => {
          return { ...ev, visible: false }
        })
      }
      return this.events.filter(
        event => isDateBefore(new Date(event.end), new Date())
      ).map((ev: Event) => {
        return { ...ev, visible: false }
      })
    }

    filterEvents (): void {
      const baseCompetences = this.filtered.baseCompetences.map((comp: KeyValue) => comp.value)
      const competences = this.filtered.competences.map((comp: KeyValue) => comp.value)
      const topics = this.filtered.topics.map((topic: KeyValue) => topic.value)
      if (baseCompetences.length > 0 || competences.length > 0 || topics.length > 0) {
        this.getEventsByCompetences({ baseCompetences, competences, topics }).then(data => {
          this.parseDates(data)
          this.$root.$emit('scroll-to-content')
        })
      } else {
        this.loadData()
        this.$root.$emit('scroll-to-content')
      }
    }

    filterByOwnCompetences (): void {
      if (this.improvementData.baseCompetenceToImprove) {
        this.filtered.baseCompetences.push(
          ...getBaseCompetenceObjectArray([this.improvementData.baseCompetenceToImprove])
        )
      }
      if (this.improvementData.competencesToImprove) {
        const competences = getCompetenceObjectArray(this.improvementData.competencesToImprove)
        this.filtered.competences = [...this.filtered.competences, ...competences]
      }
      this.filterEvents()
    }

    created (): void {
      this.loadData()
    }

    // if the user has a talent profile and the right to get their competences for advancement
    get readStudentProfileRight (): boolean {
      return hasRight(Rights.STUDENT_OWN_PROFILE_READ)
    }

    private loadData (): void {
      this.getEvents().then((data) => {
        this.parseDates(data)
      })

      this.getAllCompetences().then(data => {
        this.competenceOptions = getCompetenceObjectArray(data)
      })

      this.topic()

      if (this.readStudentProfileRight) {
        this.getCompetencesToImproveOwn().then(data => {
          if (data.content) {
            this.improvementData = data.content
          }
        })
      }
    }

    private parseDates (events: Event[]): void {
      this.events = events
      this.filteredEvents = this.getFilteredEvents()
      const actives = events.filter((co: Event) => co.state === 'ACTIVE')
      const inactives = events.filter((co: Event) => co.state === 'INACTIVE')
      const bookmarked = events.filter((co: Event) => co.bookmark)

      // Get all active dates
      this.dates = actives.map((date: Event) => new Date(date.start)).flat()
      this.dates = this.dates.concat(actives.map((date: Event) => new Date(date.end)).flat())
      this.dates = this.dates.concat(actives.map((date: Event) => getAllDatesBetweenTwoDates(date.start, date.end)).flat())

      // Get all inactive dates
      this.inactiveDates = inactives.map((date: Event) => new Date(date.start)).flat()
      this.inactiveDates = this.inactiveDates.concat(inactives.map((date: Event) => new Date(date.end)).flat())
      this.inactiveDates = this.inactiveDates.concat(inactives.map((date: Event) => getAllDatesBetweenTwoDates(date.start, date.end)).flat())

      // Get all bookmarked dates
      this.bookmarkedDates = bookmarked.map((date: Event) => new Date(date.start)).flat()
      this.bookmarkedDates = this.bookmarkedDates.concat(bookmarked.map((date: Event) => new Date(date.end)).flat())
      this.bookmarkedDates = this.bookmarkedDates.concat(bookmarked.map((date: Event) => getAllDatesBetweenTwoDates(date.start, date.end)).flat())
    }

    onEdit (event: Event): void {
      event.start = new Date(event.start)
      event.end = new Date(event.end)
      event.type = event.type === 'FULL_DAY'
      event.state = event.state === 'ACTIVE'

      event.competences = event.competences ? getCompetenceObjectArray(event.competences) : []
      event.baseCompetences = event.baseCompetences ? getBaseCompetenceObjectArray(event.baseCompetences) : []
      event.topics = event.topics ? getTopicObjectArray(event.topics) : []

      this.editEvent = event
      this.$bvModal.show('new-event-modal')
    }

    onActivate (event: Event): void {
      this.selectedEvent = event
      this.$bvModal.show('confirmation-modal-activate-event')
    }

    onBookmark (event: Event): void {
      let feedbackHeadline = ''
      let error = false
      this.$set(event, 'bookmark', !event.bookmark)
      this.selectedEvent = event
      this.$root.$emit('load')
      this.bookmarkEvent(event).then(res => {
        feedbackHeadline = this.$t((event.bookmark ? 'events.bookmark.' : 'events.unbookmark.') + res.message).toString()
        this.parseDates(this.filteredEvents)
      }).catch((res) => {
        error = true
        feedbackHeadline = this.$t((event.bookmark ? 'events.bookmark.' : 'events.unbookmark.') + res.message).toString()
      }).finally(() => {
        this.$root.$emit('end-load')
        this.$root.$emit('alert', feedbackHeadline, '', error)
      })
    }

    onDelete (event: Event): void {
      this.selectedEvent = event
      this.$bvModal.show('confirmation-modal-delete-event')
    }

    onCancel (id: string): void {
      this.$bvModal.hide(id)
    }

    confirmDeleteModal (event: Event): void {
      let feedbackHeadline = ''
      let submitSuccess = true

      this.$root.$emit('load')
      this.onCancel('confirmation-modal-delete-event')
      this.deleteEvent(event.id).then(res => {
        feedbackHeadline = this.$t('events.delete.' + res.message).toString()
        this.loadData()
        this.$emit('delete', event)
      }).catch(err => {
        feedbackHeadline = this.$t('events.delete.' + err.message).toString()
        submitSuccess = false
      }).finally(() => {
        this.$root.$emit('end-load')
        this.$root.$emit('alert', feedbackHeadline, '', !submitSuccess)
      })
    }

    confirmActivateModal (event: Event): void {
      const promise = event.state === 'ACTIVE' ? this.deactivateEvent(event.id) : this.activateEvent(event.id)
      this.$root.$emit('load')
      promise.then(() => {
        this.loadData()
        this.$emit('active', event)
      }).finally(() => {
        this.$root.$emit('end-load')
      })
    }

    onDayClick (selectedDate: Date): void {
      this.selectedDate = selectedDate
      this.filteredEvents = this.getFilteredEvents()
    }

    resetFilter (): void {
      this.selectedDate = undefined
      this.filtered = {
        competences: [],
        baseCompetences: [],
        topics: []
      }
      this.loadData()
      this.$root.$emit('scroll-to-content')
    }

    onSelectBaseCompetence (): void {
      this.filteredEvents = this.getFilteredEvents()
    }

    onSelectCompetence (): void {
      this.filteredEvents = this.getFilteredEvents()
    }

    onSelectTopic (): void {
      this.filteredEvents = this.getFilteredEvents()
    }

    topic (): void {
      this.getAllTopics().then(data => {
        this.topicOptions = getTopicObjectArray(data)
      })
    }

    @Watch('locale')
    onValueChange (): void {
      this.topic()
    }

    get locale (): string {
      return this.$i18n.locale
    }
}
