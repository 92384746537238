











































































import { Component, Prop, Watch } from 'vue-property-decorator'
import {
  compareDate,
  getAllBaseCompetencesAsKeyValue,
  getCompetenceObjectArray,
  getTopicObjectArray
} from '@/common/globals'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import BaseForm from '@/components/forms/BaseForm.vue'
import { Action } from 'vuex-class'
import { SimpleServerResponse } from '@/store/types'
import { GET_ALL_TOPICS, SAVE_EVENT } from '@/store/event/actions.type'
import { Event } from '@/store/event/types'
import moment from 'moment'
import { isDateBefore, parseDateTime } from '@/common/datePicker'
import { GET_ALL_COMPETENCES } from '@/store/kode/actions'
import MetaComponent from '@/common/MetaComponent.vue'

@Component({
  components: { BaseForm }
})
export default class Calendar extends MetaComponent {
  @Action(SAVE_EVENT)
  public postEvent: (event: Event) => Promise<SimpleServerResponse>

  @Action(GET_ALL_COMPETENCES)
  public getAllCompetences: () => Promise<Array<string>>

  @Action(GET_ALL_TOPICS)
  public getAllTopics: () => Promise<Array<string>>

  @Prop()
  writeable: boolean

  @Prop()
  manage: boolean

  @Prop()
  public dates: Date[]

  @Prop()
  public secondDates: Date[]

  @Prop()
  public thirdDates: Date[]

  @Prop()
  public editEvent: Event

  markDates: Date[] = []
  secondMarkDates: Date[] = []
  thirdMarkDates: Date[] = []

  @Watch('dates')
  onDateChanged (newValues: Date[]): void {
    this.markDates = newValues
  }

  @Watch('secondDates')
  onSecondDateChanged (newValues: Date[]): void {
    this.secondMarkDates = newValues
  }

  @Watch('thirdDates')
  onThirdDateChanged (newValues: Date[]): void {
    this.thirdMarkDates = newValues
  }

  @Watch('editEvent')
  onEditEvent (newValue: Event): void {
    if (newValue.id) {
      this.event = newValue
      this.$bvModal.show('new-event-modal')
    }
  }

  @Watch('dates')
  onShowButtonChanged (showButton: boolean): void {
    this.showButtons = showButton
  }

  selectedDate: Date | undefined = new Date()
  showButtons = true
  savePressed = false
  competenceOptions: KeyValue[] = []
  topicOptions: KeyValue[] = []

  get attributes (): any {
    return [
      {
        dot: {
          color: 'red',
          class: 'my-dot-class'
        },
        dates: this.markDates
      },
      {
        dot: {
          color: 'blue',
          class: 'my-dot-class'
        },
        dates: this.secondMarkDates
      },
      {
        dot: {
          color: 'yellow',
          class: 'my-dot-class'
        },
        dates: this.thirdMarkDates
      }
    ]
  }

  get baseCompetencOptions (): any {
    return getAllBaseCompetencesAsKeyValue()
  }

  get nextHour (): Date {
    const now = new Date()
    now.setHours(now.getHours() + 1)
    now.setMinutes(0)
    return now
  }

  event: Event = {
    title: '',
    description: '',
    start: this.nextHour,
    end: this.nextHour,
    state: true,
    baseCompetences: [],
    competences: [],
    topics: [],
    type: false,
    link: 'https://'
  }

  get fields (): Form[] {
    return [
      {
        id: 'title',
        key: 'title',
        type: InputTypes.TEXT,
        label: this.$i18n.t('events.title').toString(),
        sm: 12,
        md: 12,
        required: true,
        state: this.savePressed ? !!this.event.title : undefined
      },
      {
        id: 'description',
        key: 'description',
        type: InputTypes.TEXTAREA,
        label: this.$i18n.t('events.description').toString(),
        sm: 12,
        md: 12
      },
      {
        id: 'start',
        key: 'start',
        type: this.event.type ? InputTypes.DATE : InputTypes.DATETIME,
        label: this.$i18n.t('events.begin').toString(),
        required: true,
        check: true,
        disabledDates: {
          start: new Date(),
          end: this.event.end
        },
        minuteSteps: 15,
        state: this.savePressed ? !!this.event.start : undefined
      },
      {
        id: 'end',
        key: 'end',
        type: this.event.type ? InputTypes.DATE : InputTypes.DATETIME,
        label: this.$i18n.t('events.end').toString(),
        required: true,
        check: true,
        disabledDates: {
          start: this.event.start,
          end: undefined
        },
        minuteSteps: 15,
        state: this.savePressed ? !!this.event.end : undefined,
        timePickerOptions: { start: moment(this.event.start).format('HH:mm'), step: '00:15', end: '23:45', format: 'HH:mm' }
      },
      {
        id: 'type',
        key: 'type',
        type: InputTypes.CHECKBOX,
        label: this.$i18n.t('events.whole-day').toString()
      },
      {
        id: 'dummy',
        key: 'dummy',
        type: InputTypes.DUMMY
      },
      {
        id: 'topics',
        key: 'topics',
        type: InputTypes.MULTISELECT,
        label: this.$i18n.t('events.topic').toString(),
        options: this.topicOptions,
        class: ['pr-4']
      },
      {
        id: 'dummy',
        key: 'dummy',
        type: InputTypes.DUMMY
      },
      {
        id: 'baseCompetences',
        key: 'baseCompetences',
        type: InputTypes.MULTISELECT,
        label: this.$i18n.t('events.base-competences').toString(),
        options: this.baseCompetencOptions,
        class: ['pr-4']
      },
      {
        id: 'competences',
        key: 'competences',
        type: InputTypes.MULTISELECT,
        label: this.$i18n.t('events.competences').toString(),
        options: this.competenceOptions,
        class: ['pr-4']
      },
      {
        id: 'link',
        key: 'link',
        type: InputTypes.TEXT,
        label: this.$i18n.t('events.link').toString()
      },
      {
        id: 'location',
        key: 'location',
        type: InputTypes.TEXT,
        label: this.$i18n.t('events.location').toString()
      }
    ]
  }

  get publishField (): Form[] {
    return [
      {
        id: 'state',
        key: 'state',
        type: this.manage ? InputTypes.CHECKBOX : InputTypes.DUMMY,
        label: this.$i18n.t('events.publish-event').toString(),
        md: 12
      }
    ]
  }

  hideModal (): void {
    this.event = {
      title: '',
      description: '',
      start: this.nextHour,
      end: this.nextHour,
      state: true,
      baseCompetences: [],
      competences: [],
      topics: []
    }

    this.$bvModal.hide('new-event-modal')
    this.$emit('hideModal')
  }

  onDayClick (e: any): void {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    // When user is clicking same date again remove the selection for date
    if (this.selectedDate && compareDate(this.selectedDate, new Date(e.id))) {
      this.selectedDate = undefined
      this.showButtons = true
    } else {
      this.selectedDate = new Date(e.id)
      this.selectedDate.setHours(yesterday.getHours())
      this.showButtons = isDateBefore(this.selectedDate, yesterday)
    }
    this.$set(this.event, 'start', this.selectedDate)
    this.$set(this.event, 'end', this.selectedDate)
    this.$root.$emit('dismiss-alert')
    this.$emit('onDayClick', this.selectedDate)
  }

  addNewEvent (): void {
    this.$root.$emit('dismiss-alert')
    this.event = {
      title: '',
      description: '',
      start: this.nextHour,
      end: this.nextHour,
      state: true,
      baseCompetences: [],
      competences: [],
      topics: [],
      link: 'https://'
    }
    this.$bvModal.show('new-event-modal')
  }

  saveEvent (): void {
    if (!this.event.title || !this.event.title.trim()) {
      this.savePressed = true
      return
    }
    if (!this.event.start) {
      this.savePressed = true
      return
    }
    if (!this.event.end) {
      this.savePressed = true
      return
    }

    this.event.start = parseDateTime(this.event.start)
    this.event.end = parseDateTime(this.event.end)
    this.event.state = this.event.state ? 'ACTIVE' : 'INACTIVE'
    this.event.type = this.event.type ? 'FULL_DAY' : 'TIME'

    if (this.event.competences) {
      this.event.competences = this.event.competences.map((comp: { value: string }) => comp.value)
    }

    if (this.event.baseCompetences) {
      this.event.baseCompetences = this.event.baseCompetences.map((comp: { value: string }) => comp.value)
    }

    if (this.event.topics) {
      this.event.topics = this.event.topics.map((topic: { value: string }) => topic.value)
    }

    this.$root.$emit('load')
    this.postEvent(this.event).then(() => {
      this.$root.$emit('alert', this.$t('info.save-success', { what: this.$tc('events.event', 1) }).toString(), '', false)
      this.$emit('save')
    }).catch(() => {
      this.$root.$emit('alert', this.$t('info.save-failed', { what: this.$tc('events.event', 1) }).toString(), '', true)
    }).finally(() => {
      this.$root.$emit('end-load')
      this.hideModal()
    })
  }

  onInputChange (id: string | number): void {
    this.savePressed = false
    if (id === 'start') {
      this.$set(this.event, 'end', undefined)
    }
    // if (id === 'type') {
    //   this.$set(this.event, 'type', !this.event.type)
    // }
  }

  topic (): void {
    this.getAllTopics().then(data => {
      this.topicOptions = getTopicObjectArray(data)
    })
  }

  @Watch('locale')
  onValueChange (): void {
    this.topic()
  }

  get locale (): string {
    return this.$i18n.locale
  }

  created (): void {
    this.getAllCompetences().then(data => {
      this.competenceOptions = getCompetenceObjectArray(data)
    })

    this.topic()
  }
}
